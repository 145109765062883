import { GetReportResponse } from '@/api/dto/report';
import { closeReport, fetchFullReport } from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import router from '@/router';
import {
  inject,
  InjectionKey,
  provide,
  readonly,
  ref,
  Ref,
  shallowRef,
  watch,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

const FullReportSymbol: InjectionKey<Ref<GetReportResponse | null>> = Symbol();

export function useReportDetail(id: Ref<string>, useWatch : boolean = true) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const fullReport = shallowRef<GetReportResponse | null>(null);

  provide(FullReportSymbol, readonly(fullReport));

  const fetchReport = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await fetchFullReport(id.value);
      fullReport.value = resp;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error fetching the report.');
      router.replace({ name: 'ReportsPage' });
    }
  };

  const closeRBReport = async () => {
    if (!id.value) return;

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await closeReport(id.value);
      fullReport.value = resp;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'There was an error closing the crash file.');

      fetchState.value = FetchStates.UNFETCHED;
    }
  };
  
  if(useWatch)  {
    const stopWatchingId = watch(id, fetchReport);
    onBeforeRouteLeave(stopWatchingId);
  }
  fetchReport();

  return {
    fetchState,
    fullReport,
    fetchReport,
    closeRBReport,
  };
}

export function useReportDetailChild() {
  const fullReport = inject(FullReportSymbol);

  return { fullReport };
}
