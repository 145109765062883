
import { defineComponent, computed, defineAsyncComponent } from 'vue';
import { useReportDetail } from '@/composables/useReportDetail';
import router from '@/router';
import { FetchStates } from '@/helpers/fetch-states';

const DefaultUI = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "ReportNoUI" */ '@/components/integrations/default/NoUI.vue'
  ),
);

const AccidentPlanReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "AccidentPlanReport" */ '@/components/integrations/accident-plan/AccidentPlanReport.vue'
  ),
);
const OpenWeatherMapReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "OpenWeatherMapReport" */ '@/components/integrations/openweather/WeatherReport.vue'
  ),
);

const GeotabUserReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "GeotabUserReport" */ '@/components/integrations/geotab/UserReport.vue'
  ),
);

const GeotabDownloadableReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "GeotabDownloadableReport" */ '@/components/integrations/geotab/DownloadableReport.vue'
  ),
);

const AdditionalInformationReport = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "AdditionalInformationReport" */ '@/components/integrations/additional-information/AdditionalInformationReport.vue'
  ),
);
// const GeotabReport = () => import('./GeotabReport.vue');

const integrationCompMap: Record<string, any> = {
  none: DefaultUI,
  open_weather_map_default_ui: OpenWeatherMapReport,
  accidentplan_default_ui: AccidentPlanReport,
  geotab_user_info_ui: GeotabUserReport,
  geotab_csv_download_ui: GeotabDownloadableReport,
  additional_information_ui: AdditionalInformationReport
};

export default defineComponent({
  name: 'ReportDetailPage',
  setup() {
    const reportId = computed(
      () => router.currentRoute.value.params['id'] as string,
    );

    const { fullReport, fetchState, closeRBReport } = useReportDetail(reportId);

    const tryCloseReport = async () => {
      if (
        confirm(
          'Are you sure you want to close the report? Reports cannot be re-opened.',
        )
      ) {
        await closeRBReport();
      }
    };

    const routeToReports = () => router.push({ name: 'ReportsPage' });

    const routeBack = () => {
      if (window.history.state.back === null) {
        routeToReports();
      } else {
        router.back();
      }
    };

    const uiComp = computed(
      () =>
        integrationCompMap[fullReport.value?.report.ui ?? 'none'] ?? DefaultUI,
    );

    return {
      fullReport,
      fetchState,
      reportId,
      routeToReports,
      routeBack,
      tryCloseReport,
      FetchStates,
      integrationCompMap,
      uiComp,
    };
  },
});
